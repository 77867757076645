$app-panel-header-height: 49px;
$app-panel-footer-height: 40px;
$quick-access-toolbar-height: 64px; // 64px
$app-navbar-height: 7px;
$app-navbar-height-left: 10px;
$drawing-area-width: calc(70% - 190px);
@import "colors.scss";
@import "loginPageIndicator.scss";
@import "ie.scss";
@import "objectProperties.scss";
@import "panels.scss";
@import "messages.scss";
@import "quickAccessToolbar.scss";
@import "customIntroJs.scss";
@import "topNavigation.scss";
@import "curvesProperties.scss";
@import "printReports.scss";
@import "slider.scss";
// @import "style.scss";


/* Let's get this party started */
::-webkit-scrollbar {
    width: 5px;
    height: 5px;
    cursor: pointer !important;
}

/* Handle */
::-webkit-scrollbar-thumb {
    background: #ebebeb;
    cursor: pointer !important;
    // -webkit-box-shadow: inset 0 0 6px rgba(0,0,0,0.5); 
}

::-webkit-scrollbar-thumb:hover {
    background: #9f9f9f;
    transition: all 0.6ms, ease-in-out;
    cursor: pointer !important;
}

::-webkit-scrollbar-thumb:window-inactive {
    background: #ebebeb;
    opacity: 0.4;
}

*,
*:before,
*:after {
    box-sizing: border-box;
}

body {
    margin: 0;
    padding: 0;
}

ul,
menu,
dir {
    list-style-type: initial !important;
    margin-block-start: initial !important;
    margin-block-end: initial !important;
    margin-inline-start: 0px;
    margin-inline-end: 0px;
    padding-inline-start: 0px;
}

hr {
    margin-top: 1rem;
    margin-bottom: 1rem;
    border: 0;
    border-top: 1px solid rgba(0, 0, 0, .1);
}

h1,
h2,
h3,
h4,
h5,
h6 {
    margin: 0;
    font-weight: initial;
}

input {
    font-family: ABBvoice, sans-serif;
}

a {
    text-decoration: none;
    color: #0C74DA;
}

a:hover {
    color: #2E92FA;
}

.title {
    font-size: 16px;
    font-family: ABBvoice-bold, sans-serif;
    color: #1F1F1F;
    line-height: 20px;
}

.hidden {
    display: none;
}

.float-right {
    float: right;
}

// .ABB_CommonUX_AbbBar__root.ABB_CommonUX_AbbBar__light {
//     background-color: #2E2E2E; 
//     color: #DBDBDB;
// }

.ABB_CommonUX_Slider__root {
    padding: 0 10px 0 10px;
}

.ABB_CommonUX_AppContent__root,
.ABB_CommonUX_AbbBar__root,
.ABB_CommonUX_AppTopNavi__root {
    width: 100%;
    margin: none;
}

.ABB_CommonUX_AbbBar__root.ABB_CommonUX_AbbBar__light.ABB_CommonUX_AbbBar__abbLogo>.ABB_CommonUX_AbbBar__logo>span {
    background: url(../../libraries/abb/bundled_assets/ABB_logo_red-3DD6o.svg) no-repeat;
}

.ABB_CommonUX_Input__root>.ABB_CommonUX_Input__validationMessageContainer.ABB_CommonUX_Input__validationCollapsed {
    display: none;
}

.ABB_CommonUX_Collapsible__root.ABB_CommonUX_Collapsible__depth0.ABB_CommonUX_Collapsible__expanderOnRight>.ABB_CommonUX_Collapsible__header {
    height: 100%;
    min-height: 32px;
    padding-right: 40px;
}

.ABB_CommonUX_LoadingIndicator__root {
    width: 100px;
}

.ABB_CommonUX_AppTopNavi__item {
    cursor: pointer;
}

.ABB_CommonUX_AppTopNavi__divider {
    height: initial;
}

.ABB_CommonUX_Menu__root {
    min-width: 200px;
}

.ABB_DOCweb_Expander__custom__left {
    min-width: 188px;
}

.DOCweb__CommonUX_AppContent_DrawingArea {
    height: 100%;
    flex-direction: column;
    width: $drawing-area-width;
}

.DOCweb__CommonUX_AppCobject-properties-dividerontent_DrawingArea>.ABB_CommonUX_AppContent__root {
    width: 100%;
}

.DOCweb__CommonUX_AppContent_Properties {
    z-index: 0;
}

.DOCweb_CommonUX_ProgressIndicator__custom {
    position: absolute;
    top: 13px;
    left: 360px;
    width: 100px;
}

.DOCweb_CommonUX_TabControl__root_custom {
    margin: 0 40px 0 0;
    overflow: hidden;
}

.DOCweb_CommonUX_TabControl__root_custom_marginless {
    margin: 0 !important;
}

.DOCweb_CommonUX_Icon_Hover:hover {
    background-color: silver;
    transition: all 0.6s ease-in-out;
}

.DOCweb_CommonUX_TabControl__right_arrow {
    margin-top: -37px;
}

.DOCweb_CommonUX_TabControl__footer {
    // margin-top: -32px;
    display: -ms-flexbox;
    display: flex;
    -ms-flex-align: center;
    align-items: center;
    -ms-flex-pack: end;
    justify-content: flex-end;
    padding: 1rem;
    border-top: 1px solid #e9ecef;
}

.DOCweb_CommonUX_TabControl__footer_tutorialCheckbox {
    display: -ms-flexbox;
    display: flex;
    -ms-flex-align: center;
    align-items: center;
    -ms-flex-pack: end;
    justify-content: flex-start;
}

.DOCweb_CommonUX_ToggleSwitch__custom_right {
    float: right;
}

.ABB_CommonUX_Dialog__dialog>.ABB_CommonUX_Dialog__root>.ABB_CommonUX_Dialog__content {
    width: 95vw;
    max-width: 1200px;
}

.ABB_CommonUX_Input__root.ABB_CommonUX_Input__isValid.ABB_CommonUX_Input__showValidationResult>.ABB_CommonUX_Input__inputWrapper:after {
    background: none;
}

.DOCweb_CommonUX_Select__custom {
    border: 1px solid #bababa !important;
    font-family: ABBVoice, sans-serif;
    color: #1F1F1F;
    background: #FFFFFF;
    height: 32px !important;
    width: 100%;
    padding: 4px 23px 4px 8px;
    font-size: 14px;
    text-overflow: ellipsis;
    box-shadow: none;
    outline: none;
    cursor: pointer;
    -webkit-appearance: none;
}

.DOCweb_CommonUX_Select__label {
    color: #686868;
}

.DOCweb_CommonUX_Select__custom:hover {
    background-color: #ebebeb;
    border: 1px solid #9f9f9f;
}

.DOCweb_CommonUX_Select__wrapper {
    position: relative;
}

.DOCweb_CommonUX_Select__wrapper:after {
    font-family: 'ABB-UI-Icons-16x16', sans-serif !important;
    content: '\ea08';
    width: 16px;
    height: 16px;
    position: absolute;
    top: 10px;
    right: 4px;
    pointer-events: none;
    line-height: 1;
}

.DOCweb_CommonUX_TabControl__Active {
    color: #0052FF;
}

.DOCWeb__Autocompute {
    vertical-align: top;
    font-size: 16px;
}

.ABB_CommonUX_Collapsible__root>.ABB_CommonUX_Collapsible__header>span:last-child {
    white-space: initial;
}

.ABB_CommonUX_Collapsible__root.ABB_CommonUX_Collapsible__depth1.ABB_CommonUX_Collapsible__expanderOnRight>.ABB_CommonUX_Collapsible__header {
    min-height: 32px;
    padding-right: 32px;
    height: 100%;
}

.DOCweb_CommonUX__PDF_Preview {
    width: 49%;
    display: inline-flex;
    cursor: pointer;
    border: 1px solid #9f9f9f;
    margin-right: 5px;
}

.DOCweb_CommonUX__PDF_Preview:hover {
    background-color: #3D3D3D;
}

.DOCweb_CommonUX__PDF_Preview>canvas {
    width: 100% !important;
    height: 100% !important;
}

.DOCweb_CommonUX__PDF_Preview_Selected>canvas,
.DOCweb_CommonUX__PDF_Preview>canvas:hover {
    opacity: 0.9;
}

.DOCweb_CommonUX__PDF_Preview_Selected:hover,
.DOCweb_CommonUX__PDF_Preview_Selected {
    background-color: #0052FF !important;
}

.ABB_CommonUX_Collapsible__header>span {
    white-space: normal;
}

.ABB_CommonUX_TabControl__tabItemRoot {
    max-height: calc(100vh - 220px);
    padding: 0;
}

.DOCweb_CommonUX__Toolbar {
    border-top: 1px solid #bababa;
}

.DOCweb_CommonUX__ObjectPropertiesHeader {
    padding: 6px;
    border-bottom: 1px solid #9f9f9f;
}

.DOCweb_CommonUX__ObjectPropertiesHeader>div:last-child {
    text-align: right;
}

.DOCWeb_project-explorer-pages-dragging>.ABB_CommonUX_Input__inputWrapper>.object-properties-input-field {
    border-top: 1px solid #bababa !important;

}

.DOCWeb_project-explorer-input>.ABB_CommonUX_Input__inputWrapper>.object-properties-input-field {
    border-top: 1px solid #bababa !important;
}


.object-properties-input>.ABB_CommonUX_Input__root>.ABB_CommonUX_Input__inputWrapper>input,
.object-properties-input>.DOCweb_CommonUX_Select__wrapper>.DOCweb_CommonUX_Select__custom {
    border: none !important;
}

.object-properties-input>.ABB_CommonUX_Input__root>.ABB_CommonUX_Input__validationCollapsed {
    display: none;
}

.counter {
    vertical-align: top;
    padding: 0 2px 0 2px;
}

.row {
    margin: 0;
}

.ABB_Message_Dialog_Center {
    z-index: 99900;
    position: absolute;
    margin-left: 30%;
    width: 40%;
}

.splash_screen_background {
    background-color: rgba(0, 0, 0, 0.6);
    width: 100vw;
    position: absolute;
    height: 100vh;
    z-index: 99999999;

}

.splash_screen {
    z-index: 99999;
    margin: auto;
    margin-top: 20vh;
}

.disabled {
    color: $abb-grey-140;
}

.btn-abb-save {
    width: 160px;
    background-color: $abb-blue-5;
    color: $abb-grey-255;
    border: 1px solid $abb-blue-5;
    border-bottom: 1px solid $abb-blue-6;
    margin-left: 10px;
}

.btn-abb-save:hover {
    background-color: $abb-blue-4;
    border: 1px solid $abb-blue-4;
}

.btn-abb-login {
    width: 180px;
}


.panel-container {
    display: flex;
    min-height: 300px;
    width: 100%;
    user-select: none;
}

.about-list {
    text-align: left;
    margin: 2px 16px 30px 1px;
    height: 100px;
    border-left: 1px solid gray;
    border-right: 1px solid gray;
    border-top: 1px solid gray;
    border-bottom: 1px solid gray;
    overflow-y: auto;
    overflow-x: hidden;
    width: calc(100% - 0px);
}


.imgPreview {
    text-align: center;
    margin: 2px 16px 30px 1px;
    height: 100px;
    width: 200px;
    border-left: 1px solid gray;
    border-right: 1px solid gray;
    border-top: 1px solid gray;
    border-bottom: 1px solid gray;

    img {
        width: 100%;
        height: 100%;
    }
}

.previewText {
    width: 100%;
    margin-top: 20px;
}


/**
Fonts styles
*/
.h4,
h4 {
    font-size: 18px;
}

.h5,
h5 {
    font-size: 14px;
    padding-left: 8px !important;
}

/**
 * Custom checkbox style
 */
/**
 * Custom disabled items style
 */
select[disabled] {
    background-color: #ebebeb;
    cursor: initial;
    opacity: .5;
}

select[disabled]:hover {
    background-color: #ebebeb;
}

/*
    Main paper container
*/
.drawing-area {
    // height: calc(100vh - #{$app-navbar-height} - #{$quick-access-toolbar-height} + 19px);
    padding: 0;
    position: relative;
}

.drawing-area .panel {
    position: absolute;
    width: 100%;
    bottom: 19px;
    min-width: 300px;
}

.app-paper-container {
    background-color: $abb-grey-255;
}

.app-paper-container .joint-paper-scroller {
    overflow: hidden;
    height: 100vh;
}

/**
 * Style for user profile navigation
 */
.user-profile-nav {
    padding-top: 38px;
    right: 30px;
    position: absolute;
    display: flex;
}

.user-profile-nav span {
    padding-left: 5px;
}

.user-profile-nav span a {
    color: $abb-grey-255;
}

.user-menu {
    width: 350px;
    height: 200px;
    top: 47px;
    right: -1px !important;
    border-radius: 0;
}

.user-email {
    font-size: 12px;
}

.user-circle {
    border-radius: 50%;
    background-color: $abb-blue-5;
    width: 38px;
    height: 38px;
    text-align: center;
    font-size: 22px;
    color: $abb-grey-220;
}

.panel-group-container {
    padding-right: 0;
    margin: 0;
    width: 100%;
    height: 100%;
    background-color: #EBEBEB;
    z-index: 1;
}

.app-panel-header {
    background-color: $abb-grey-70;
    color: $abb-grey-255;
    font-family: ABBvoice-bold, Arial, Helvetica, sans-serif;
    padding: 13px 15px;
    height: $app-panel-header-height;
    min-width: 200px;
}

.app-panel-header .panel-title {
    font-size: 11.5px;
}

.app-panel-header .right-elements {
    float: right;
    padding-right: 10px;
    padding-top: 2.5px;
    cursor: pointer;
}

.app-panel-header .errors-counter>.ui_error_circle1 {
    color: $abb-alarm-red;
}

.app-panel-header .errors-counter>.ui_warning_triangle {
    color: $abb-alarm-orange;
}

.app-panel-header .errors-counter>.ui_check_mark_circle1 {
    color: #0CA919;
}

.app-panel-header .errors-counter {
    min-width: 90px;
    text-align: center;
}

.app-panel-header .errors-counter>span {
    vertical-align: middle;
}

.app-panel-header .errors-counter>.counter {
    padding-left: 10px;
    padding-right: 15px;
}

.app-panel-body {
    bottom: 0;
    overflow-y: scroll;
    background-color: $abb-grey-250;
    border-bottom: 1px solid $abb-grey-200;
    border-left: 1px solid $abb-grey-200;
    border-right: 3px solid $abb-grey-200;
    padding: 0;
    overflow-x: hidden;
}

.app-panel-disabled {
    background-color: #9EAAB0 !important;
}

.app-panel-body .row {
    margin: 0;
}

.app-panel-footer {
    height: $app-panel-footer-height;
    background-color: $abb-grey-250;
    border-left: 1px solid $abb-grey-200;
}

.panel-group .panel+.panel {
    margin-top: 1px;
}

.margin-bottom {
    margin-bottom: -11px;
}

.panel-group-left {
    padding-right: 0;
    padding-left: 0;
    position: relative;
    min-width: 350px;
    height: 1px;
    background-color: #EBEBEB;
}

.panel-group-right {
    padding-right: 0;
    padding-left: 0;
    position: relative;
}

.span-link {
    cursor: pointer;
    padding-right: 5px;
}

.panel {
    border: none;
}


.panel-body.panel-network-validation-error,
.panel-network-validation-error .panel-header {
    border: 1px solid #ffa7ae !important;
}

.panel-network-validation-error .panel-header {
    color: $abb-alarm-red !important;
}

.app-panel-header .accordion-toggle:after {
    font-family: 'ABB-UI-Icons-24x24', sans-serif;
    content: "\e997";
    float: right;
    padding-top: 4px;
    padding-right: 15px;
    color: $abb-grey-255;
    font-size: 14px;
}

.app-panel-header .accordion-toggle.collapsed:after {
    font-family: 'ABB-UI-Icons-24x24', sans-serif;
    padding-top: 4px;
    padding-right: 15px;
    content: "\e93a";
    font-size: 14px;
}

.icon-toolbar {
    text-align: center;
    cursor: pointer;
}

.side-bar-content-splitter-accordion {
    width: 100%;
    padding: 0;
}

.side-bar-content-splitter-accordion .panel-header {
    border: 1px solid $abb-grey-160;
    background-color: $abb-grey-160;
    color: $abb-grey-255;
    text-align: left;
    vertical-align: middle;
    padding-top: 9px;
    padding-left: 10px;
    height: 37px;
    font-family: ABBvoice-regular, Arial, Helvetica, sans-serif;
    font-size: 11px;
    cursor: pointer;
}

.expandable-message {
    padding-right: 140px;
}


.side-bar-content-splitter-accordion .panel-header .accordion-toggle:after {
    font-family: 'Glyphicons Halflings', sans-serif;
    content: "\2212";
    float: left;
    padding-right: 5px;
}

.side-bar-content-splitter-accordion .panel-header .accordion-toggle.collapsed:after {
    content: "\2b";
}

// .margintop {
//     margin-top: 8px;
// }
.side-bar-content-splitter-accordion .panel-body {
    padding: 0;
}

.project-properties-print-accordion {
    padding-left: 20px !important;
}

.side-bar-content-section-name {
    vertical-align: middle;
    font-size: 14px;
    font-family: ABBvoice-bold, sans-serif;
    padding-top: 10px;
    padding-bottom: 10px;
    text-align: left;
}

.side-bar-content-section-name .row:first-child {
    border-bottom: 1px solid #e6e6e6;
    padding-bottom: 7px;
}

.side-bar-content-section-name-sign {
    padding-left: 1px;
    padding-right: 1px;
}

.switch-board-name {
    color: $abb-blue-5;
    text-align: center;
}

.icon:hover {
    cursor: pointer;
}

/*
  Stencil items
*/


// ul.stencil-group-items {
//     padding-left: 10px;
// }
ul.stencil-group-items li {
    list-style-type: none;
    float: left;
    width: 94px;
    cursor: pointer;
    margin-top: 5px;
    margin-left: 5px;
    text-align: center;
    line-height: 1.2;
}

ul.stencil-group-items-inside-tabbar li {
    display: inline-block;
    width: 45%;
    float: none;
    text-align: none;
}

ul.stencil-group-items li span.stencil-group-item-name {
    font-size: 13px;
    /* float: left; */
    /* position: relative;
    left: -20px; */
    line-height: 1;
}

ul.stencil-group-items li img.stencil-group-item-icon {
    width: 100%;
    height: 42px;
}

ul.stencil-group-items li img.stencil-group-item-icon-macro {
    width: 100%;
    height: 82px;
}

ul.stencil-group-items li span.glyphicon {
    font-size: 30px;
    display: block;
    margin-right: 5px;
}


//*** Check if present ***//
.joint-element *,
.marker-source,
.marker-target {
    vector-effect: none;
}

.joint-link .connection {
    vector-effect: none;
}

.joint-link.joint-theme-default .connection-wrap {
    stroke-width: 3px;
}

.joint-link.joint-theme-default .connection-wrap:hover {
    opacity: 0.3;
    stroke-opacity: 0.3;
}

.stencil-group {
    margin-top: 7px;
    // border-right: 1px solid #e6e6e6;
    // padding: 0 6px 0 6px;
    text-align: center;
}

.stencil-group-inside-tabbar {
    text-align: left;
}

.stencil-group h5 {
    font-size: 15px;
    border-top: 1px solid #e6e6e6;
    border-bottom: 1px solid #e6e6e6;
}

.stencil-group::after {
    content: "";
    display: block;
    clear: both;
}

//*** Check if present ***//
/*
    Disable selection information box and halo box
*/
.joint-selection .box {
    display: none;
}

.joint-selection .selection-wrapper {
    pointer-events: auto;
    margin-left: 0;
    margin-top: 0;
}

.joint-halo .box {
    display: none;
}

.joint-halo .handles {
    background-color: transparent;
    border: 1px dashed $abb-grey-90;
    width: 100%;
    height: 100%;
    /* width: 120%;
    height: 130%;
    margin-left: -10%;
    margin-top: -12%; */
}

/*
    ContextToolbar dialog overides
*/
.joint-context-toolbar.joint-theme-default .tool:disabled {
    color: #C3C3C3;
}

.joint-context-toolbar.joint-theme-default .tool:disabled:hover {
    background: $abb-grey-255;
    cursor: auto;
}


/*
    Toolbar buttons overides
*/
button.joint-widget.joint-theme-default:disabled {
    color: #C3C3C3;
}

button.joint-widget.joint-theme-default:disabled:hover {
    background: $abb-grey-255;
    cursor: auto;
}

/*
    Paper drag and drop
*/
#dd-paper-container .joint-paper {
    position: absolute;
    z-index: 10000;
    background: 0 0 !important;
}

/*
    Device attributes
*/
.doc-device .attributes text {
    font-size: 2.5px;
    font-family: ABBvoice-regular, Arial, Helvetica, sans-serif;
    font-style: normal;
    text-decoration: none;
    text-anchor: start;
    stroke-width: 0;
    fill: inherit;
}

.doc-device .attributes text.title {
    font-family: ABBvoice-medium, Arial, Helvetica, sans-serif;
}

/*
    Highlighters
*/

.joint-port-body {
    fill-opacity: 0.1;
    stroke-opacity: 1;
    stroke: red;
}

.joint-port-body.connected {
    fill-opacity: 0;
    stroke-opacity: 0;
    stroke: #666;
}

[magnet=true]:not(.joint-element):hover {
    opacity: 1.0;
    stroke-opacity: 1.0;
    fill-opacity: 0.1;
}

.hl-connecting {
    /* stroke-width: 2 !important; */
    fill: green !important;
    stroke: #8bc34a !important;
    fill-opacity: 0.25 !important;
    stroke-opacity: 1 !important;
}

/**
 * FreeTransform style overrides
 */
// .joint-free-transform div.nw,
// .joint-free-transform div.n,
// .joint-free-transform div.ne,
// .joint-free-transform div.se,
// .joint-free-transform div.s,
// .joint-free-transform div.sw {
//     display: none;
// }

.joint-free-transform.joint-theme-default {
    border: none;
}

/**
 * STYLES: ALL MODALS IN PROJECT
 */
.modal-content {
    border-radius: 0px;
}


.modal .modal-footer {
    height: 70px;
    justify-content: space-between;

}

.app-toolbar-row-container .nav-tabs-container {
    padding-top: 10px;
    background-color: $abb-grey-255;
}

.tabs-width-text-align {
    flex: 1;
    text-align: center;
}

///*** Check here ***///
.li>.a {
    color: black;
}

///*** Check here ***///

.text-left {
    padding-right: 25px;
}

.nav>li {
    position: relative;
    display: block;
    background-color: $abb-grey-230;
}

///*** Check here ***///
.selected-input {
    background-color: $abb-blue-5;
    color: $abb-grey-255;
    cursor: default;
}

///*** Check here ***///

/**
 * STYLES: OPTIONS -> LABLE MANAGEMENT
 */
[draggable] {
    -moz-user-select: none;
    -khtml-user-select: none;
    -webkit-user-select: none;
    user-select: none;
    /* Required to make elements draggable in old WebKit */
    -khtml-user-drag: element;
    -webkit-user-drag: element;
}

.label-error {
    text-align: justify;
    color: red;
}

.label-header,
.label-checkbox-header {
    border-top: 1px solid #9f9f9f;
    background-color: $abb-grey-240;
}

.label-header label {
    padding-left: 10px;
}

.label-radio,
.label-checkbox-header,
.label-checkbox {
    text-align: center;
    border-right: 1px solid #9f9f9f;
    padding-left: 0px;
    padding-right: 0px;
    border-bottom: 1px solid #9f9f9f;
}

.device-table-radio-label {
    margin-left: calc(50% - 8px);
}

.label-radio {
    height: 100%;
    min-height: 33px;
}


.label-container {
    padding: 0;
    height: 480px;
    // overflow: auto;
}

.categories-list {
    margin-left: 15px;
    overflow-y: scroll;
    width: 450px;
}

.labels-list {
    margin-left: 25px;
    width: 450px;
}

.label-target-element {
    border-bottom: 1.5px solid #136FDC;
    border-top: 1px solid #9f9f9f;
}

.selected-label-category {
    color: #136FDC;
    background-color: #DCEEFC;
    border: 1px solid #136FDC;
}

.selected-label-category,
.selected-label-category label {
    cursor: default;
}

.selected-label-category label {
    padding-left: 5px;
}

.not-selected-label-category label,
.not-selected-label-category {
    cursor: pointer;
}

.not-selected-label-category label {
    padding-left: 5px;
}

.draggable-item {
    cursor: move;
    padding-left: 5px;
}

.icon-nut {
    float: right;
    cursor: pointer;
}

.footer-app-bar {
    margin-bottom: 20px;
}

/**
 * Styles related to wizard
 */
.info-project-description-field {
    height: 130px
}

.button-wizard {
    margin-top: -8px;
}

.label-wizard {
    margin-top: -31px;
}

.info-project-input-label {
    padding-top: 10px;
}

.description-modal-label {
    // padding-top: 10px;
    font-size: 18px;
}

.wizard-supply-table {
    padding-bottom: 50px;
    padding-right: 30px;
    padding-top: 35px;
}

.lv-default-tolerance-row {
    padding-top: 20px;
    padding-bottom: 20px;
}

///*** Check here ***///
.dd {
    border: none;
}

.divider {
    visibility: hidden;
}

.ddArrow {
    visibility: hidden;
}

.ddTitleText {
    background-color: $abb-grey-255;
}

.ddTitleText>img {
    height: 320px;
    cursor: pointer;
}

///*** Check here ***///
.circuit-breaker-img-text {
    text-align: center;
}

.circuit-breaker-img {
    max-width: 100%;
    max-height: 100%;
    height: 100%;
}

.circuit-breaker-img-container {
    text-align: center;
    margin-top: 50px;
    height: 250px;
    width: 300px;
}

.breaker-table {
    height: 200px;
    overflow-x: hidden;
    overflow-y: auto;
    // padding-left: 15px;
    padding-right: 15px;
}

/*
    Curves
*/
.curves {
    background-color: white;
    clear: both;
    max-width: 1000px;
    margin: 0px 5px 0px 5px;
}

/*
    Curves functions
*/
.curves-function {
    font-family: ABBvoice-boldABBvoice-bold, Arial, Helvetica, sans-serif;
}

.curves-function label {
    font-weight: 500;
}

/**
 * Start page
 */
.start-page-main {
    position: absolute;
    top: 1px;
    background: $abb-grey-255;
    bottom: 0;
    width: 100%;
}

.margin-top-start-page {
    margin-top: 10vh;
}

.margin-bottom-start-page {
    margin-bottom: 10vh;
}

.padding-icon-start-page {
    margin-right: 10px;
}

.padding-top-tabbar-circuit {
    padding-top: 4px;
}

.padding-left-tabbar-circuit {
    padding-left: 32px;
}

.link-text-start-page {
    cursor: pointer;
    color: $abb-blue-5;
}

.text-start-page,
.text-start-page span,
.link-text-start-page span {
    color: $abb-grey-38;
    cursor: default;
}

.link-text-start-page:hover {
    text-decoration: none;
    color: $abb-blue-4;
}

.abb-image {
    height: 80px;
    margin-left: 25px;
}

/**
     * Styles for options page
     */
.options-select {
    padding-bottom: 10px;
}


/**
 * Styles for project list
 */
.project-list-table {
    border: none;
}

.project-list-table-header {
    background-color: $abb-grey-70;
    color: $abb-grey-250;
}

.project-list-table-body {
    cursor: pointer;
    padding-left: 0;
}

.project-list-table-body table tbody>tr:hover {
    background-color: $abb-blue-5;
    color: $abb-grey-250;
}

.project-list-table-body table tbody>tr>td {
    border: none;
}

/**
 * Styles for Project properties => print manager dialog
 */
.print-manager-container {
    padding: 0;
    height: 580px;
    overflow: hidden;
}

.print-manager-header {
    margin-bottom: 15px;
}

.print-manager-header-print {
    padding-left: 25px;
}

.print-manager-reports-scroll {
    //overflow: auto;
    overflow-y: auto;
    overflow-x: hidden;
    height: 350px;
    // width: calc(100% - 0px);
}

.radio-alignment-print-manager {
    padding-left: 15px;
    margin-top: 0px;
}

.red-asterisk {
    padding-left: 14px;
}

.red-asterisk:before {
    content: "*";
    color: $abb-alarm-red
}

.clear-both-object-properties-tabbar {
    clear: both;
}

.new-scenario-input {
    padding-left: 44px;
    visibility: hidden;
}

.textAreaAttr {
    font-size: 5px;

}

.icon-protection-inderect-col-center {
    margin-left: 42%;
    cursor: pointer;
}


.checkboxTableAccordion {
    //  width: 95%;
    padding-left: 15px;
    margin-bottom: 15px;
    overflow: auto;
    overflow-x: hidden;
    max-height: 160px;
    border-bottom: 1px solid;
    border-right: 1px solid;
    border-left: 1px solid;
    border-color: #9f9f9f;
}

// JOINT
.joint-selection.joint-theme-default .selection-wrapper {
    border: 2px dashed $abb-grey-180;
    margin-left: -6px;
    padding-right: 14px;
    margin-top: -6px;
    padding-bottom: 14px;
    box-sizing: content-box;
}

.editable-input {
    width: calc(100% - 25px);
    height: 100%;
    // margin-top: -4px;
}

.editable-input.ABB_CommonUX_Input__root.ABB_CommonUX_Input__light.ABB_CommonUX_Input__normal>.ABB_CommonUX_Input__inputWrapper>input {
    border-right: none;
}

.select-editable {
    height: 33px;
    position: relative;
}

.select-editable select {
    position: absolute;
    top: 0px;
    left: 0px;
    font-size: 14px;
    width: 100%;
    margin: 0;
}

.editible-trafo-modal-select {
    border: 1px solid #D2D2D2;
    height: 30px;
}

.editible-trafo-modal-input {
    height: 30px;
    border-left: 1px solid #D2D2D2;
    border-bottom: 1px solid #D2D2D2;
    border-top: 1px solid #D2D2D2;
}

.select-editable input {
    position: absolute;
    top: 0px;
    left: 0px;
    width: 90%;
    font-size: 12px;
}

.select-editable input[disabled] {
    opacity: 0 !important;
}

.select-editable select:focus,
.select-editable input:focus {
    outline: none;
}

.editable-select-discrimination {
    width: 90%;
    height: 36px;
    border: none;
    font-size: 12px
}

.editable-select-column {
    padding: 0px;
    height: 100%;
}

.border-right-none {
    border-right: none;
}

.editable-select-transformator-button {
    border-left: none;
    border-top: 1px solid $abb-grey-210;
    border-bottom: 1px solid $abb-grey-210;
    border-right: 1px solid $abb-grey-210;
    border-width: 1px !important;
    border-color: $abb-grey-210 !important;
}

.no-border {
    border: none;
}

.loadUserListErrorMessage {
    color: $abb-alarm-red;
}

.joint-paper-background,
.joint-paper-grid,
.joint-paper>svg {
    overflow: visible;
}

.wizard-toggle-button-label {
    margin-top: 8px;
}

.wizard-toggle-button {
    width: 130px
}

// .ABB_CommonUX_ToggleButton__text {
//     margin-top: 8px;
// }

.apparent-power-toggle-buttons {
    // padding-left: 83px;
    font-size: 12px;
}

// .padding-left-10 {
//     padding-left: 10px;
// }

.ApparentPowerTypesLabel {
    margin-top: 10px;
    // margin-left: 20px;
    margin-bottom: 5px;
}

.margin-bottom-5 {
    margin-bottom: 5px;
}

.select-for-print-manager {
    margin-bottom: 5px;
    padding-left: 10px;
}

.select-for-device-families,
.space-after-input-for-device-families {
    margin-bottom: 5px;
}

//login page
.sign-in-button {
    margin-top: -140px;
    margin-left: 4%;
    z-index: 999;
}

.checkbox-list-obsolete-family-color {
    color: #CB6745 !important;
}

.checkbox-list-disabled-family-color {
    color: grey !important;
}

//

.print-manager-checkbox-background-main {
    background-color: lightgrey;
}

.DOCWeb_print-manager-checkbox-background-sub {
    background-color: #e9e9e9;
    padding-left: 30px !important;
}

//
.padding-top-0 {
    padding-top: 0px;
}

.padding-top-8-user-name {
    padding-top: 8px;
}

.width-country-select {
    width: 360px;
}

.DOCWeb_CommonUX_multiple_inputs {
    overflow-wrap: break-word;
    height: 33px;
    text-align: center;
    vertical-align: middle;
    color: #1f1f1f;
    font-size: 14px;
    letter-spacing: 0px;
    line-height: 12px;
    padding: 0;
}

.DOCWeb_CommonUX_multiple_inputs input {
    padding: 4px !important;
    padding-left: 2px !important;
    padding-right: 0px !important;
}

.padding-top-short-circuit-current-labels {
    padding-top: 8px;
}

.icon-scenario {
    margin-top: 30px;
}

.padding-left-8 {
    padding-left: 8px !important;
}

.padding-left-15 {
    padding-left: 15px;
}

.margin-left-15 {
    margin-left: -15px;
}

.margin-top-6 {
    margin-top: 6px;
}

.padding-left-140 {
    padding-left: 140px;
}

.padding-left-0 {
    padding-left: 0;
}

.margin-top-10 {
    margin-top: 10px;
}

.padding-left-5 {
    padding-left: 5px;
}

.margin-left-5 {
    margin-left: 5px;
}

.col-lg-0>label {
    margin-bottom: 0px;
}

.margin-bottom-20 {
    margin-bottom: 20px;
}

.panel-network-validation-warning span {
    color: $abb-alarm-orange;
}

.panel-network-validation-error span {
    color: $abb-alarm-red;
}

// position of user-country pop-up
.user-country-popup-tune-up .popup-content {
    left: -180px !important;
}

// position of arror pointing to 'user name' in 'user pop-up'
.user-country-popup-tune-up .popup-content>div {
    left: 260px !important;
}

.margin-start-page {
    margin-top: 10vh;
}

// label {
//     margin-bottom: 0px;
// }
.DOCweb_CommonUX_Select_margin {
    margin-top: -4px;
}

.DOCweb_CommonUX_Label_Centered_for_ABB_Inputs {
    margin-top: 25px;
    text-align: center;
}

.scenario-controls-padding-top-18 {
    padding-top: 18px;
}

.scenario-controls-icons-padding-top-18 {
    padding-top: 22px;
}

.project-properties-short-circuit-checkbox-width {
    max-width: 50px;
}

.display-inline-flex {
    display: inline-flex;
}

.DOCweb_CommonUX_VoltageSideBarLabel {
    border-top: 1px solid #9f9f9f;
    border-bottom: 1px solid #9f9f9f;
    font-size: 14px;
    font-weight: bold;
    text-align: center;
}

.DOCWEB_empty_line {
    height: 14px;
    border-bottom: 1px solid #9f9f9f;
}

.DOCWeb_scenario_add_icon_nav_bar_padding_top {
    padding-top: 8px;
}

.DOCWEB_group-5-icon {
    margin-top: 5px;
    margin-left: 6px;
}

.DOCWeb_no-margin-bottom {
    margin-bottom: 0px !important;
}

.DOCWeb_border-top .DOCWeb_border-top-checkbox {
    border-top: 1px solid #9f9f9f;
}

.DOCWeb_border-top-checkbox {
    padding-top: 0px !important;
}

.DOCweb_hr_protectionAgainstInderect {
    float: right;
    width: 76%;
    margin-top: 11px;
}

.DOCweb_curves_diagramSelect {
    margin: 10px;
}

.DOCweb_curves_diagramSelect .DOCweb__CommonUX_GridColumn1 {
    text-align: center;
    padding-top: 5px;
}

.DOCweb_separation-space-for-lv-cable {
    border-bottom: 1px solid #9f9f9f;
    height: 12px;
}

.DOCWeb_borders-project-explorer-dragged,
.DOCWeb_borders-project-explorer {
    border-bottom: 1px solid #9f9f9f;
    height: 32px;
}

.DOCWeb_borders-project-explorer-dragged {
    border: 1px solid #9f9f9f;
}

.DOCWeb_Project-Explorer-Width {
    width: 95%;
}

.DOCWeb_Project-Explorer-Icons {
    padding-left: 1px;
}

.DOCWeb_Nest-For-Drag-n-Drop-Project-Explorer,
.DOCWeb_Drag-n-Drop-Project-Explorer {
    height: 90%;
}

.DOCWeb_Options_Labels_Left_Column,
.DOCWeb_Options_Labels_Right_Column {
    height: 64vh;
    overflow-y: auto;
}

.DOCWeb_Scenario_Select_Label {
    margin: 3px auto;
    text-align: center;
    display: block;
    font-family: ABBvoice, sans-serif;
}

.DOCWeb_aligment-for-device-families {
    padding-left: 40px;
}

// for centering switch in 'Show' tab of curves diagram
.DOCWeb_label-switch .ABB_CommonUX_ToggleSwitch__root {
    margin-left: calc(50% - 24px)
}

.import-errors-tables {
    white-space: pre;
}

// make a text a bit smaller to fit into label more accurately
.ABB_DOCWeb_TU_font_13px {
    font-size: 13px;
}

.NotMediumVoltageMessageText {
    text-align: center;
}

.modal-buttons {
    text-align: end;
}

.print-manager-container-dialog {
    min-height: 600px;
}

.DOCWeb_eye-switch-off,
.DOCWeb_eye-switch-on {
    cursor: pointer;
}

.DOCWeb_eye-switch-on {
    color: #3366FF;
}

.device-svg {
    width: 42px;
    color: blue;
    position: absolute;
    left: 0;
}

.custom-svg-button {
    padding-left: 20px;
}

.ABB_CommonUX_RadioGroup__root>label {
    margin-left: 0px;
}

.ABB_CommonUX_RadioGroup__root>label>input {
    position: initial;
}

.CURVES_footer {
    height: 30px;
}

.tablet {
    width: 500px;
}

.mobile {
    width: 350px;
}

.mobile1 {
    width: 300px;
}

.productDescription {
    margin-top: 30px;
    margin-bottom: 30px;
}

.phonesNav>a {
    padding-left: 0 !important;
    padding-right: 0 !important
}

.phonesNav>a>div {
    margin: 0 4px !important;
}

.ABB_CommonUX_TabControl__root.ABB_CommonUX_TabControl__primary>ul>li {
    margin-right: 5px;
}

.ABB_CommonUX_Dialog__content>.ABB_CommonUX_TabControl__root>ul {
    overflow-y: hidden;
    overflow-x: scroll;
}

.diagram-tabs-scroll {
    overflow-x: auto !important;
    margin: 0 5px 0 0 !important;
}

// navbar icons
.disabled>a>div>i {
    color: $abb-grey-140;
}

.hidden-configuration-tab {
    visibility: hidden;
}

.curves-tab>ul>li:not(.ABB_CommonUX_TabControl__disabled).ABB_CommonUX_TabControl__active:after {
    background-color: #FFD700 !important
}

.support-mail-request-container {
    min-height: 600px;
}

.curve-function-row>.DOCweb__CommonUX_GridColumn4:first-child {
    padding-right: 5px;
}

.icon-protection-indirect-col-center {
    margin-left: 42%;
    cursor: pointer;
}

.ABB_CommonUX_Dialog__closeButton {
    z-index: 1;
}